<template>
  <div class="history">
    <Nav/>
      <main id="main" role="main">
        <section id="lead">
          <h2><img src="@/assets/img/head_history.svg" alt="由緒"></h2>
          <div class="box">
            <ul class="slider">
              <li><img src="@/assets/img/susanoo.jpg" alt="素戔嗚尊"></li>
              <li><img src="@/assets/img/susanoo.jpg" alt="素戔嗚尊"></li>
              <li><img src="@/assets/img/susanoo.jpg" alt="素戔嗚尊"></li>
              <li><img src="@/assets/img/susanoo.jpg" alt="素戔嗚尊"></li>
            </ul>
          </div>
          <div class="box">
            <p>樹齢約1000年のご神木です。一説に信濃の国の語源「信濃は科野なり」と云われています。「科」の語源は「結ぶ・くくる・しばる」と云われ、開運・縁結びのご神木として信仰されてきました。今も軽井沢のパワースポットのひとつとしてご参拝者の信仰を集めています。しなの木は高地でよく見る広葉樹で葉はハートの形をしており、7月頃に白く小さい花をたくさん咲かせます。</p>
          </div>
        </section>

        <section id="footer" class="footer">
          <picture>
            <source srcset="@/assets/img/stones_pc.svg" media="(min-width: 1000px)">
            <img src="@/assets/img/stones_sp.svg">
          </picture>
          <div class="box">
            <address>熊野皇大神社<br>〒389-0101 長野県北佐久郡軽井沢町峠町１</address>
            <ul>
              <li><a target="_blank" rel="nofllow" href="https://www.instagram.com/kumanokoutai/?hl=ja"><span><img src="@/assets/img/icn_instagram.svg" alt="Instagram"></span></a></li>
              <li><a target="_blank" rel="nofllow" href="https://www.facebook.com/kumanokoutaijinja/"><img src="@/assets/img/icn_facebook.svg" alt="facebook"></a></li>
            </ul>
          </div>
        </section>
      </main>
    <Foot/>
  </div>
</template>

<script>
// @ is an alias to /src
import Foot from '@/components/Foot.vue'
import Nav from '@/components/Nav.vue'

export default {
  name: 'Home',
  components: {
    Foot,
    Nav
  }
}
</script>

<style lang="scss" scoped>
    .box {
        p {
            color: #fff;
            text-align: left;
        }
    }
</style>
